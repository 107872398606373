import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require('jquery-backstretch');
require('lightcase');
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

//EXTRA MEDIA QUERY FOR LARGE RETINA DISPLAYS
Foundation.Interchange.SPECIAL_QUERIES['largeretina'] = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)';

$(document).foundation();

$(document).ready(function () {
    //reposition header because of cookiemessage
    var cookieheight = $("#cookiesplease").height();
    if ($(".cookiesplease-shown")) {
      $('header').css({
        'margin-top': cookieheight
      });
    }
  
    //lightcase
  $('a[data-rel^=lightcase]').lightcase({
    transition: 'fadeInline',
    showSequenceInfo: false,
    showTitle: true,
    showCaption: false,
    maxWidth: 1200,
    maxHeight: 750
  });
  
    // SCROLL ANIMATIONS
    var $animation_elements = $('.animation-element');
    var $window = $(window);
  
    function check_if_in_view() {
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);
      var sticky_height = $(".sticky-container").height(); //to add sticky section to top position
  
      $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);
  
        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position + sticky_height) &&
          (element_top_position <= window_bottom_position)) {
          $element.addClass('in-view');
        } else {
          $element.removeClass('in-view');
        }
      });
    }
  
    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll', check_if_in_view);
  
  });